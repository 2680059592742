<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-archive"></i>
            ارشيف الرسائل
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <label for="">بحث في الأرشيف</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="q"
                  placeholder="ابحث هنا برقم الهاتف او بمحتوى الرسالة او بالتاريخ..."
                />
                <select v-model="status">
                  <option value="all">الكل</option>
                  <option value="1">تم الارسال</option>
                  <option value="2">فشل الارسال</option>
                  <option value="3">ايقاف مؤقت</option>
                  <option value="0">جاري الارسال</option>
                </select>
                
                <select v-model="status2">
                  <option value="all">SMS: الكل</option>
                  <option value="1">SMS: تم الارسال</option>
                  <option value="2">SMS: فشل الارسال</option>
                  <option value="0">SMS: جاري الارسال</option>
                </select>
                
              </div>
            </div>
            <div class="form-group col-12 col-lg-2">
              <br />
              <button class="btn btn-primary btn-sm" @click="getMessages()">
                <i class="fa fa-search"></i>
                بحث
              </button>
            </div>
            <div class="form-group col-12 col-lg-4">
              <br />
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
                ><template #button-content>
                  <i class="fa fa-cogs"></i>
                  خيارات الأرشيف
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                </template>
                <b-dropdown-item @click="changeMessagesStatus('0', '3')">
                  <i class="fa fa-pause"></i>
                  ايقاف مؤقت للرسائل <br />الجاري ارسالها
                </b-dropdown-item>
                <b-dropdown-item @click="changeMessagesStatus('0', '2')">
                  <i class="fa fa-stop"></i>
                  الغاء الرسائل <br />الجاري ارسالها
                </b-dropdown-item>
                <b-dropdown-item @click="changeMessagesStatus('3', '0')">
                  <i class="fa fa-play"></i>
                  استكمال الرسائل <br />المتوقفة مؤقتاً
                </b-dropdown-item>
                <b-dropdown-item @click="changeMessagesStatus('3', '2')">
                  <i class="fa fa-stop"></i>
                  الغاء الرسائل <br />المتوقفة مؤقتاً
                </b-dropdown-item>
                <b-dropdown-item @click="changeMessagesStatus('5', '2')">
                  <i class="fa fa-stop"></i>
                  الغاء الرسائل <br />المجدولة
                </b-dropdown-item>
                <b-dropdown-item @click="changeMessagesStatus('5', '0')">
                  <i class="fa fa-play"></i>
                  بدء الرسائل <br />المجدولة الآن
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-1>
                  <i class="fa fa-edit"></i>
                  تغيير الاجهزة<br />للرسائل الجارية
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCanceled()">
                  <i class="fa fa-trash"></i>
                  حذف رسائل "تم الالغاء"
                </b-dropdown-item>
                <b-dropdown-item @click="deleteAll()">
                  <i class="fa fa-trash"></i>
                  حذف الارشيف كاملاً
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="col-12 g"></div>
          <div class="col-12 table-responsive">
            <table class="table table-hover table-striped" id="table">
              <thead>
                <th>الهاتف</th>
                <th>محتوى الرسالة</th>
                <th>التاريخ</th>
                <th>الحالة</th>
                <th>ارسلت من خلال</th>
              </thead>
              <tbody>
                <tr v-for="message in messages" :key="message._id">
                  <td>
                    <span v-if="usersObj[message.to]"><i class="fa fa-user-check text-success"></i><span v-if="usersObj[message.to].name">{{ usersObj[message.to].name }}<br></span></span>
                    {{ message.to }}</td>
                  <td>
                    <textarea
                      style="width: 100%; border: none"
                      rows="3"
                    >{{ message.message }}</textarea>
                    <span v-if="message.file != ''">
                      <a :href="message.file" target="_blank">
                        <i class="fa fa-file"></i>
                        عرض المرفق
                      </a>
                    </span>
                  </td>
                  <td>{{ message.date }}</td>
                  <td>
                    <span class="badge bg-info" v-if="message.status == 0">
                      <div class="spinner-border" role="status"></div>
                      في انتظار الارسال
                    </span>
                    <span class="badge bg-success" v-if="message.status == 1">
                      <i class="fa fa-check"></i>
                      تم الارسال
                    </span>
                    <span class="badge bg-warning" v-if="message.status == 3">
                      <i class="fa fa-clock-o"></i>
                      ايقاف مؤقت
                    </span>
                    <span class="badge" style='background: #B931FC; color: white' v-if="message.status == 5">
                      مجدولة
                    </span>
                    <span v-if="message.status == 5">{{ message.schedule }}</span>
                    <span class="badge bg-danger" v-if="message.status == 2">
                      <i class="fa fa-times"></i>
                      {{ message.response }}
                    </span>
                    <span v-if="message.sms">
                      <span v-if="message.sms.status != -1" style="cursor:pointer;">
                        <span class="badge bg-info" v-if="message.sms.status == 0">
                      SMS:
                      <div class="spinner-border" role="status"></div>
                      في انتظار الارسال
                    </span>
                    <span class="badge bg-success" v-if="message.sms.status == 1">
                      SMS:
                      <i class="fa fa-check"></i>
                      تم الارسال
                    </span>
                    <span class="badge bg-warning" v-if="message.sms.status == 3">
                      SMS:
                      <i class="fa fa-clock-o"></i>
                      ايقاف مؤقت
                    </span>
                    <span class="badge bg-danger" v-if="message.sms.status == 2"  @click="smsInfo(message.sms)">
                      SMS:
                      <i class="fa fa-times"></i>
                      اضغط للتفاصيل
                    </span>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span v-if="message.sent_from == 'exten'">من الاداة</span>
                    <span
                      v-if="
                        message.sent_from != '' &&
                        message.sent_from != 'exten' &&
                        devices[message.sent_from]
                      "
                    >
                      {{ devices[message.sent_from].title }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-secondary"
            @click="
              page = page - 1;
              getMessages();
            "
            v-if="page > 0"
          >
            <i class="fa fa-arrow-right"></i>
            السابق
          </button>
          <button class="btn btn-default">
            {{ page + 1 }}
          </button>
          <button
            class="btn btn-primary"
            @click="
              page = page + 1;
              getMessages();
            "
            v-if="messages.length > 0"
          >
            التالي
            <i class="fa fa-arrow-left"></i>
          </button>
           &nbsp;
          <b-dropdown
                id="dropdown-2"  style="float:left; "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                
                size="sm"
                ><template #button-content>
                  <i class="fa fa-print"></i>
                  طباعة / تصدير &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </template>
                <b-dropdown-item @click="printTable()">
                  <i class="fa fa-print"></i>
                 طباعة هذه الصفحة 
                </b-dropdown-item>
                <b-dropdown-item @click="toExcel()">
                  <i class="fa fa-download"></i>
                 تصدير هذه الصفحة 
                </b-dropdown-item>
              </b-dropdown>
        </div>
      </div>
      <br><br><br>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تغيير الاجهزة للرسائل الجارية"
      ok-only
      hide-footer
    >
      <p>اختر الاجهزة</p>
      <div v-for="device in devicesList" :key="device._id" class="g">
        <b-form-checkbox
          name="check-button"
          v-model="selected_devices"
          inline
          :value="device._id"
        >
          {{ device.title }}
        </b-form-checkbox>
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="changeDevices()">
          تغيير الاجهزة في الرسائل الآن
        </button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BOverlay,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      json: {
        head: [
          "الهاتف",
          "الرسالة",
          "التاريخ",
          "الحالة",
          "ارسلت من خلال",
        ],
        fileName:
          "الواتس الذكي" +
          "-" +
          new Date().toISOString().split("T")[0] +
          "-" +
          ".csv",
      },
      status2: "all",
      loading: true,
      messages: [],
      devices: {},
      devicesList: [],
      usersObj: {},
      q: null,
      selected_devices: [],
      status: "all",
      page: 0,
      inertval: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    if (window.location.hash) {
      g.q = window.location.hash.split("#")[1];
    }
    $.post(api + "/user/devices/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.devicesList = r.response;
        r.response.forEach((element) => {
          g.devices[element._id] = element;
        });
        g.getMessages();
        g.inertval = setInterval(() => {
          g.getMessages(true);
        }, 5000);
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
      
      $.post(api + "/user/contacts/list", {
        jwt: this.user.jwt,
        group_id: 'all',
      })
        .then(function (r) {
          r.response.forEach(function(a){
            g.usersObj[a.phone] = a
          })
          g.loading = false;
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
  },
  beforeDestroy() {
    if (this.inertval) {
      clearInterval(this.inertval);
    }
  },
  methods: {
    deleteAll(){
      var g = this;
      if (confirm("متأكد من حذف الارشيف كاملاً؟")) {
        var pass = prompt("للحذف برجاء كتابة كلمة المرور", "")
      g.loading = true;
      $.post(api + "/user/messages/delete-all", {
        jwt: this.user.jwt,
        password: pass
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getMessages();
          } else {
            alert(r.response);
          }
        })
        .catch(function () {
          g.loading = false;
          alert("حدث خطأ");
        });
      }
    },
    deleteCanceled(){
      var g = this;
      if (confirm("متأكد من حذف الرسائل الملغية؟")) {
        var pass = prompt("للحذف برجاء كتابة كلمة المرور", "")
      g.loading = true;
      $.post(api + "/user/messages/delete-canceled", {
        jwt: this.user.jwt,
        password: pass
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getMessages();
          } else {
            alert(r.response);
          }
        })
        .catch(function () {
          g.loading = false;
          alert("حدث خطأ");
        });
      }
    },
    changeDevices() {
      var g = this;
      if (g.selected_devices.length) {
        g.loading = true;
        $.post(api + "/user/messages/change-devices-all", {
          jwt: this.user.jwt,
          arr: g.selected_devices,
        })
          .then(function (r) {
            g.loading = false;
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.getMessages();
            } else {
              alert("حدث خطأ2");
            }
          })
          .catch(function () {
            g.loading = false;
            alert("حدث خطأ");
          });
      } else {
        alert("برجاء اختيار الاجهزة");
      }
    },
    changeMessagesStatus(f, t) {
      var g = this;
      if (confirm("متأكد من هذا الاجراء؟")) {
        g.loading = true;
        $.post(api + "/user/messages/change-status-all", {
          jwt: this.user.jwt,
          from: f,
          to: t,
        })
          .then(function (r) {
            g.loading = false;
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.getMessages();
            } else {
              alert("حدث خطأ2");
            }
          })
          .catch(function () {
            g.loading = false;
            alert("حدث خطأ");
          });
      }
    },
    printTable(){
      var g =this;
      var win = window.open("")
      win.document.write(`
      <html dir="rtl">
        <head>
          <title>smart-whats.com</title>
          <style>
            table, td, th {
              border: 1px solid;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
          </style>
        </head>
        <body>
          ${$("#table").parent().html()}
        </body>
      </html>
      `)
      $("textarea", win.document).each(function(){
        $(this).parent().css("max-width", "300px !important")
        $(this).parent().html($(this).val())
      })
      win.print()
    },
    getMessages(noloading = false) {
      var g = this;
      if (!noloading) {
        g.loading = true;
      }
      $.post(api + "/user/messages/archive", {
        jwt: this.user.jwt,
        q: g.q,
        page: g.page,
        status: g.status,
        status2: g.status2
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.messages = r.response;
          } else {
            alert("حدث خطأ2");
          }
        })
        .catch(function () {
          g.loading = false;
          alert("حدث خطأ");
        });
    },
    toExcel() {
      const { head, fileName } = this.json;
      var g = this
      var v = this.messages.map((x) => {
        return {
          phone: x.to,
          message: x.message?.replaceAll("\n", "  "),
          date: x.date,
          status: x.status?.toString()?.replace("0", "في الانتظار")?.replace("1", "تم الارسال")?.replace("2", x.response)?.replace("3", "ايقاف مؤقت"),
          sent_from: g.devices[x.sent_from] ? g.devices[x.sent_from].title : ""
        };
      });
      jsontoexcel.getXlsx(v, head, fileName);
    },
    smsInfo(info){
      alert(`portal:        ${info?.portal}\nusername: ${info?.username}\npassword: ${info?.password}\nsender:      ${info?.sendername}\nالرد:   ${info.response}`);
    }
  },
};
</script>

<style>
</style>